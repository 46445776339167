import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 4-4-4-4\\@85% 1RM`}</p>
    <p>{`Glute Ham Raise 4-4-4-4 (weighted if possible)`}</p>
    <p>{`then,`}</p>
    <p><em parentName="p">{`Advanced:`}</em></p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`4-Clean & Jerks (225/155)`}</p>
    <p>{`6-Bar Muscle Ups`}</p>
    <p>{`40ft HS Walk`}</p>
    <p><em parentName="p">{`RX: `}</em></p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`4-Clean & Jerks (155/105)`}</p>
    <p>{`6-CTB Pullups`}</p>
    <p>{`80ft Bear Crawl`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be closed on Sunday (Rest Day) for Easter.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start April 29th.  For more info
email Eric at: Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      